





































































































































































@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.reportWrapper > hr {
  margin: 2rem 0;
}

.reportWrapper:last-of-type > hr {
  display: none;
}
